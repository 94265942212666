import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@unocss/reset/tailwind.css';
// eslint-disable-next-line import/no-unresolved
import 'unfonts.css';
// eslint-disable-next-line import/no-unresolved
import 'virtual:uno.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ScreenProvider } from './contexts/providers/screen-provider.tsx';
import { ThemeProvider, theme } from './libs/chakra';
import './main.css';
import { routes } from './router.tsx';

Sentry.init({
  dsn: 'https://3eea7f0194a2cabdd38517342c462cd0@o354244.ingest.us.sentry.io/4507540995244032',
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_CIRCLE_TAG,
  enableTracing: false,
  tracesSampleRate: 0.05,
});

const router = createBrowserRouter(routes);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ScreenProvider>
          <RouterProvider router={router} />
        </ScreenProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
