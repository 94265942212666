import { useQuery } from '@tanstack/react-query';

import { streamingApi } from '@/api/streaming-api';
import { QUERY_KEY } from '@/constants/query-key';

export const useBroadcastsViewerCountQuery = () => {
  const [query, setQuery] = useState<{
    ivideo_pk: string;
    ivideo_sk: string;
  } | null>(null);

  const queryData = useQuery({
    queryKey: [QUERY_KEY.BROADCASTS.INDEX, query],
    queryFn: () =>
      streamingApi.getUserCount({
        ivideo_pk: query!.ivideo_pk,
        ivideo_sk: query!.ivideo_sk,
      }),
    refetchInterval: 60000, // 1 minute
    enabled: !!query?.ivideo_sk && !!query.ivideo_pk,
  });
  return {
    setQuery,
    refetch: queryData.refetch,
    count: queryData.data?.channel_viewer_count ?? 1,
  };
};
