import { Input, Text, useDisclosure } from '@chakra-ui/react';
import {
  ChatRoom,
  ConnectionState,
  ChatToken,
  ChatMessage,
  SendMessageRequest,
} from 'amazon-ivs-chat-messaging';
import { nanoid } from 'nanoid';

import { KeyboardEvent, ReactElement, forwardRef } from 'react';

import { streamingApi } from '@/api/streaming-api';
import { StartChatUserModal } from '@/components/start-chat-user-modal';
import { TypecastLogo } from '@/components/typecast-logo';
import { TDS } from '@/components/ui';
import { HEALTH_CHECK_AUTHOR_ID } from '@/constants';
import { useChatUsernameValue } from '@/contexts/chat-username-provider';
import { useBroadcastsViewerCountQuery } from '@/hooks/use-broadcast-viewer-count';
import { useBroadcastsQueryWithParams } from '@/hooks/use-broadcasts-query-with-params';
import { useScreenStore } from '@/stores/screen';
import { gtag } from '@/utils/gtag';

const AUTHOR_ID = nanoid();

export function AwsIvsChat(): ReactElement {
  const [chatRoom, setChatRoom] = useState<ChatRoom | null>(null);
  const username = useChatUsernameValue();
  const [connectionState, setConnectionState] =
    useState<ConnectionState>('disconnected');
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const chatInputRef = useRef<{ value: string; clear: () => void }>(null);
  const fetchLockRef = useRef(false);
  const { isDesktop } = useScreenStore();

  const { data } = useBroadcastsQueryWithParams();
  const {
    setQuery,
    count: viewerCount,
    refetch: requestChannelViewerCount,
  } = useBroadcastsViewerCountQuery();

  useEffect(() => {
    if (
      !data ||
      data.broadcasts.length === 0 ||
      !data.broadcasts[0].ivideo ||
      !data.broadcasts[0].ivideo.ivideo_pk
    ) {
      return;
    }
    if (!fetchLockRef.current) {
      fetchLockRef.current = true;

      const { ivideo_pk, ivideo_sk } = data.broadcasts[0].ivideo;
      setQuery({ ivideo_pk, ivideo_sk });
      const fetchTokenAndInitializeRoom = async () => {
        const room = new ChatRoom({
          regionOrUrl: 'us-west-2',
          tokenProvider: () => tokenProvider(ivideo_pk, ivideo_sk, AUTHOR_ID),
        });
        room.connect();
        setChatRoom(room);
      };
      const fetchGetChatHistory = async () => {
        const res = await streamingApi.getLastChats(ivideo_pk);
        setMessages(
          res.chats
            .filter(chat => chat.author_id !== HEALTH_CHECK_AUTHOR_ID)
            .sort((chatA, chatB) => chatA.send_ts - chatB.send_ts)
            .map(chat => ({
              id: chat.id,
              sender: {
                userId: chat.author_id,
              },
              content: chat.content,
              sendTime: chat.send_at,
              attributes: {
                author_id: chat.author_id,
                author_name: chat.author_name,
              },
            })),
        );
      };
      fetchTokenAndInitializeRoom();
      fetchGetChatHistory();
    }

    if (!chatRoom) {
      return;
    }

    const unsubscribeOnConnecting = chatRoom.addListener('connecting', () => {
      setConnectionState('connecting');
    });

    const unsubscribeOnConnected = chatRoom.addListener('connect', () => {
      setConnectionState('connected');
      requestChannelViewerCount();
    });

    const unsubscribeOnDisconnected = chatRoom.addListener('disconnect', () => {
      setConnectionState('disconnected');
    });

    const unsubscribeOnMessageReceived = chatRoom.addListener(
      'message',
      (message: ChatMessage) => {
        if (message.attributes?.author_id === HEALTH_CHECK_AUTHOR_ID) {
          return;
        }
        setMessages(msgs => [...msgs, message]);
      },
    );

    const unsubscribeOnMessageDeleted = chatRoom.addListener(
      'messageDelete',
      deleteEvent => {
        setMessages(prev =>
          prev.filter(message => message.id !== deleteEvent.messageId),
        );
      },
    );

    return () => {
      unsubscribeOnConnecting();
      unsubscribeOnConnected();
      unsubscribeOnDisconnected();
      unsubscribeOnMessageReceived();
      unsubscribeOnMessageDeleted();
    };
  }, [chatRoom, data]);

  /**
   * @NOTE messages 업데이트 완료 후 스크롤을 맨 아래로 이동 (setMessage이후 바로 하면 비동기 문제로 마지막에서 두번째로 이동함)
   */
  useEffect(() => {
    scrollRef.current?.scroll({
      top: scrollRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  const sendMessageByKeyboardEnter = async (
    e: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.nativeEvent.isComposing) return;
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const sendMessage = async () => {
    if (!chatInputRef.current || !chatRoom) {
      return;
    }
    gtag('send_message_button_click');
    try {
      const messageToSend = chatInputRef.current.value;
      const content = `${messageToSend.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`;
      const request = new SendMessageRequest(content, {
        author_id: AUTHOR_ID,
        author_name: username,
      });
      chatRoom.sendMessage(request);
      chatInputRef.current.clear();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="h-11/12 flex flex-col justify-between gap-8">
      <div className="border border-grey-600 flex flex-col justify-between rounded-xl h-full">
        <div className="bg-white rounded-t-xl flex flex-col py-4 items-center">
          {isDesktop && <TypecastLogo />}
        </div>
        <div className="p-2">
          <span className="bg-red-500 w-3 h-3 rounded-full inline-block mr-2"></span>
          <span className="text-white ">{viewerCount} viewers</span>
        </div>
        <div
          className="h-64 md:h-full overflow-y-scroll flex flex-col"
          ref={scrollRef}
        >
          <div className="flex flex-col p-4 gap-4 mt-auto">
            {messages.map(message => {
              return (
                <div key={message.id} className="text-white flex gap-2">
                  <div className="flex w-full">
                    <Text fontWeight="bold" className="opacity-75 mr-1.5">
                      {message.attributes?.author_name ?? 'Unknown'}:
                    </Text>
                    <Text>{message.content}</Text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        {Boolean(username) && (
          <div className="flex items-center gap-2">
            <Text className="text-white min-w-20 max-w-30 truncate">
              {username}
            </Text>
          </div>
        )}
        <ChatInput
          ref={chatInputRef}
          disabled={connectionState !== 'connected'}
          onKeyDown={sendMessageByKeyboardEnter}
        />
        <TDS.Button onClick={sendMessage}>Send</TDS.Button>
      </div>
    </div>
  );
}

type ChatInputProps = {
  disabled: boolean;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const ChatInput = forwardRef(function ChatInput(
  props: ChatInputProps,
  ref,
): ReactElement {
  const [messageToSend, setMessageToSend] = useState('');
  const username = useChatUsernameValue();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useImperativeHandle(ref, () => {
    return {
      value: messageToSend,
      clear: () => setMessageToSend(''),
    };
  }, [messageToSend]);

  const checkUsernameExist = () => {
    if (Boolean(username) === false) {
      gtag('open_join_the_chat_modal_input_click');
      onOpen();
    }
  };

  return (
    <>
      <Input
        className="text-white"
        size="lg"
        value={messageToSend}
        onChange={e => {
          if (Boolean(username) === false) {
            return;
          }
          setMessageToSend(e.target.value);
        }}
        onClick={checkUsernameExist}
        {...props}
      />
      {isOpen && <StartChatUserModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
});

async function tokenProvider(
  ivideo_pk: string,
  ivideo_sk: string,
  author_id: string,
): Promise<ChatToken> {
  let token: string = '';
  try {
    //Chat API
    const response = await streamingApi.createChatroomToken(
      ivideo_pk,
      ivideo_sk,
      author_id,
    );
    token = response.token;
  } catch (error) {
    console.error('Error:', error);
  }
  return {
    token: token,
    // sessionExpirationTime: new Date(token.sessionExpirationTime),
    // tokenExpirationTime: new Date(token.tokenExpirationTime),
  };
}
