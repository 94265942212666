import { useParams } from 'react-router-dom';

import { useBroadcastsQuery } from '@/hooks/use-broadcasts-query';
import { useCharacterLocation } from '@/hooks/use-character-location';

export const useBroadcastsQueryWithParams = () => {
  const params = useParams();
  const character = useCharacterLocation();
  return useBroadcastsQuery(
    'uid' in params ? `?uid=${params.uid}` : `?handle=${character}`,
  );
};
