import { Actor } from '@/constants/actor';

export { cn } from './cn';

export const translateToKoreanCharacterName = (_query: string) => {
  switch (_query) {
    case Actor.camila:
      return '카밀라';
    case Actor.changu:
      return '찬구';
    case Actor.hajun:
      return '하준';
  }
};

/**
 * @see https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  console.error('x: ', x);
  throw new Error("Didn't expect to get here");
}

const GOTO_TYPECAST_URL = import.meta.env.VITE_GOTO_TYPECAST_URL as string;
export const gotoTypecast = () => {
  window.open(GOTO_TYPECAST_URL, '_blank');
};
