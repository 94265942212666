import { useQuery } from '@tanstack/react-query';

import { streamingApi } from '@/api/streaming-api';
import { QUERY_KEY } from '@/constants/query-key';

export const useBroadcastsQuery = (query: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.BROADCASTS.INDEX, query],
    queryFn: () => streamingApi.getBroadcasts(query),
    refetchInterval: 1000 * 60 * 2, //1000ms(1s) * 60 * 3 = 2분
  });
};
