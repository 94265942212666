import { useLocation } from 'react-router-dom';

import { Actor } from '@/constants/actor';

export const useCharacterLocation = () => {
  const location = useLocation();
  const character = location.pathname.split('@')[1];
  const ActorList = [Actor.camila, Actor.changu, Actor.hajun] as string[];
  const isAvailableCharacter = ActorList.includes(character);

  return isAvailableCharacter ? character : Actor.camila;
};
