import axios from 'axios';

const baseURL = `${import.meta.env.VITE_LIVE_STAGE_URL}/api`;

export const streamingApi = {
  getBroadcasts,
  createChatroomToken,
  getLastChats,
  getUserCount,
};

async function getBroadcasts(query: string = '') {
  const response = await axios.get<BroadCastsDTO>(
    `${baseURL}/v1/ivideo/broadcasts${query}`,
  );
  return response.data;
}
async function createChatroomToken(
  ivideo_pk: string,
  ivideo_sk: string,
  author_id: string,
) {
  const response = await axios.post<{ token: string }>(
    `${baseURL}/v1/ivideo/${ivideo_pk}/${ivideo_sk}/chatroom_token`,
    {
      author_id: author_id,
    },
  );
  return response.data;
}

async function getLastChats(ivideo_pk: string) {
  const response = await axios.get<ChatsDTO>(
    `${baseURL}/v1/ivideo/${ivideo_pk}/last_chats`,
  );
  return response.data;
}

async function getUserCount({
  ivideo_pk,
  ivideo_sk,
}: {
  ivideo_pk: string;
  ivideo_sk: string;
}) {
  const response = await axios.get<BroadCastsViewerCountDTO>(
    `${baseURL}/v1/ivideo/${ivideo_pk}/${ivideo_sk}/user_count`,
  );
  return response.data;
}

type BroadCastsDTO = {
  broadcasts: BroadcastDTO[];
};

type BroadcastDTO = {
  broadcast_handle: string;
  broadcast_pk: string;
  broadcast_thumbnail_url: string | null;
  ivideo: Ivideo;
};

type ChatsDTO = {
  chats: {
    type: string;
    id: string;
    r_id: string;
    content: string;
    send_at: Date;
    send_ts: number;
    author_id: string;
    author_name: string;
    author_img_url: string;
    author_type: string;
  }[];
};

export type Ivideo = {
  channel_id: string;
  chatroom_id: string;
  chatroom_url: string;
  input_url: string;
  ivideo_pk: string;
  ivideo_sk: string;
  ividep_status: 'START';
  output_url: string;
  start_ts: number; //timestamp
  stop_ts: number | null; //timestamp
} | null;

type BroadCastsViewerCountDTO = {
  channel_viewer_count: number;
};
