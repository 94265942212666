import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { TDS } from '@/components/ui';
import { useChatUsernameAction } from '@/contexts/chat-username-provider';
import { gtag } from '@/utils/gtag';
import { removeSpecialCharacters } from '@/utils/guard';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function StartChatUserModal(props: Props): React.ReactElement {
  const { isOpen, onClose } = props;
  const [name, setName] = useState('');
  const setUsername = useChatUsernameAction();

  const createUserAndStartChat = () => {
    const _name = removeSpecialCharacters(name).trim();
    if (Boolean(_name) === false) {
      return;
    }
    gtag('join_the_chat_button_click');
    setUsername(_name);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="p-8">
        <ModalHeader className="text-center">
          <Text fontWeight="700" fontSize="x-large" color="grey-900">
            Viewer
          </Text>
        </ModalHeader>
        <ModalBody className="flex flex-col">
          <div className="flex flex-col gap-2">
            <label htmlFor="username">Nickname</label>
            <Input
              id="username"
              placeholder="nickname"
              value={name}
              onChange={e => setName(e.target.value)}
              onKeyDown={e => {
                if (e.nativeEvent.isComposing) return;
                if (e.key === 'Enter') {
                  createUserAndStartChat();
                }
              }}
            />
            <TDS.Button
              className="w-full mt-2"
              size="md"
              onClick={createUserAndStartChat}
            >
              Join the chat
            </TDS.Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
