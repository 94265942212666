import { Route, createRoutesFromElements } from 'react-router-dom';

import { Actor } from '@/constants/actor';
import LiveStreamingChatPage from '@/pages/live-streaming-chat-page';
import NotFoundPage from '@/pages/not-found-page';

export const routes = createRoutesFromElements(
  <Route>
    <Route path="/" element={<LiveStreamingChatPage />}>
      <Route path="u/:uid" element={<LiveStreamingChatPage />} />
      <Route path={`@${Actor.camila}`} element={<LiveStreamingChatPage />} />
      <Route path={`@${Actor.changu}`} element={<LiveStreamingChatPage />} />
      <Route path={`@${Actor.hajun}`} element={<LiveStreamingChatPage />} />
    </Route>
    ,
    <Route path="*" element={<NotFoundPage />} />,
  </Route>,
);
