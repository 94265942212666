import { Text } from '@chakra-ui/react';

import typecastLogo from '@/assets/typecast-logo.svg';
import { gotoTypecast } from '@/utils';

export function TypecastLogo() {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      gotoTypecast();
    }
  };
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={gotoTypecast}
      onKeyDown={handleKeyDown}
      style={{ cursor: 'pointer' }}
    >
      <img
        className="w-40 pt-1"
        src={typecastLogo}
        alt="Typecast Logo"
        loading="lazy"
      />
      <div className="flex gap-0.5 pl-3">
        <Text as="span" fontWeight="medium">
          Live Streaming
        </Text>
        <Text
          as="span"
          color="gray.800"
          className="rounded-lg border border-grey-400 px-0.5"
          fontWeight="medium"
        >
          Beta
        </Text>
      </div>
    </div>
  );
}
