import {
  forwardRef,
  Checkbox as _Checkbox,
  type CheckboxProps as _CheckboxProps,
} from '@chakra-ui/react';

type StyleObject = {
  [x: string]: Style;
};
type Style = string | number | StyleObject;
type ColorScheme = 'primary';
type Size = 'xl' | 'lg' | 'md';

export type CheckboxProps = {
  colorScheme?: ColorScheme;
  size?: Size;
} & Omit<_CheckboxProps, 'colorScheme' | 'size'>;

export const Checkbox = forwardRef<CheckboxProps, 'input'>(
  ({ size = 'lg', colorScheme = 'primary', children, ...props }, ref) => {
    return (
      <_Checkbox
        colorScheme={colorScheme}
        rounded={4}
        ref={ref}
        css={{
          ...sizeStyle[size],
          lineHeight: 1,
        }}
        {...props}
      >
        {children}
      </_Checkbox>
    );
  },
);

Checkbox.displayName = 'Checkbox';

const sizeStyle: Record<Required<CheckboxProps>['size'], StyleObject> = {
  xl: {
    '.chakra-checkbox__control': {
      borderRadius: 4,
      width: 24,
      height: 24,
      svg: {
        width: '12px',
      },
    },
  },
  lg: {
    '.chakra-checkbox__control': {
      borderRadius: 4,
      width: 20,
      height: 20,
    },
    svg: {
      width: '10px',
    },
  },
  md: {
    '.chakra-checkbox__control': {
      borderRadius: 4,
      width: 16,
      height: 16,
    },
    svg: {
      width: '10px',
    },
  },
};
