import { cva } from 'class-variance-authority';

import { HTMLAttributes } from 'react';

import { cn } from '../../utils';

type Color = 'primary' | 'grey' | 'whiteAlpha' | 'error' | 'info';
type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export type SpinnerProps = {
  color?: Color;
  size?: Size;
  label?: string;
} & Omit<HTMLAttributes<HTMLSpanElement>, 'children'>;

export const Spinner = forwardRef<HTMLSpanElement, SpinnerProps>(function (
  {
    size = 'md',
    color = 'whiteAlpha',
    label = 'Loading...',
    className,
    ...props
  },
  ref,
) {
  return (
    <i
      className={cn(spinnerVariants({ size, color }), className)}
      ref={ref}
      {...props}
    >
      {label && <span className="sr-only">{label}</span>}
    </i>
  );
});

const spinnerVariants = cva('i-t-spinner animate-spin', {
  variants: {
    color: {
      primary: 'color-primary',
      grey: 'color-grey-600',
      whiteAlpha: 'color-white',
      error: 'color-error',
      info: 'color-info',
    },
    size: {
      xl: 'text-11',
      lg: 'text-7',
      md: 'text-6',
      sm: 'text-4',
      xs: 'text-2',
    },
  },
});

Spinner.displayName = 'Spinner';
