import { isNull } from 'es-toolkit';

import React, { createContext, useContext, useState } from 'react';

const ChatUsernameValueContext = createContext<string | null>(null);
const ChatUsernameActionContext = createContext<React.Dispatch<
  React.SetStateAction<string>
> | null>(null);

interface ChatUsernameProviderProps {
  children: React.ReactNode;
}

export function ChatUsernameProvider({ children }: ChatUsernameProviderProps) {
  const [username, setUsername] = useState('');
  return (
    <ChatUsernameActionContext.Provider value={setUsername}>
      <ChatUsernameValueContext.Provider value={username}>
        {children}
      </ChatUsernameValueContext.Provider>
    </ChatUsernameActionContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useChatUsernameValue() {
  const value = useContext(ChatUsernameValueContext);
  if (isNull(value)) {
    throw new Error(
      'useChatUsernameValue는 ChatUsernameProvider 필요로 합니다',
    );
  }
  return value;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useChatUsernameAction() {
  const value = useContext(ChatUsernameActionContext);
  if (isNull(value)) {
    throw new Error(
      'useChatUsernameAction은 ChatUsernameProvider 필요로 합니다',
    );
  }
  return value;
}
