import { Text } from '@chakra-ui/react';

import { createTimeModel, useTimeModel } from 'react-compound-timer';
import { useParams } from 'react-router-dom';

import broadcast from '@/assets/images/broadcast.png';
import TypecastLogoWhite from '@/assets/typecast-logo-white.svg';
import { AwsIvsChat } from '@/components/aws-ivs-chat';
import { AwsIvsPlayer } from '@/components/aws-ivs-player';
import { StartStreamingButton } from '@/components/start-streaming-button';
import { TypecastLogo } from '@/components/typecast-logo';
import { TDS } from '@/components/ui';
import { BUSINESS_CONTACT_URL, GO_TO_CONSOLE } from '@/constants';
import { ChatUsernameProvider } from '@/contexts/chat-username-provider';
import { useBroadcastsQueryWithParams } from '@/hooks/use-broadcasts-query-with-params';
import { useScreenStore } from '@/stores/screen';
import { dayjs } from '@/utils/time';

export default function LiveStreamingChatPage() {
  const params = useParams();
  const isUserBroadcast = 'uid' in params;
  const { data, isSuccess } = useBroadcastsQueryWithParams();

  if (
    (isSuccess && data.broadcasts.length === 0) ||
    data?.broadcasts[0].ivideo === null
  ) {
    return (
      <MainLayout>
        <NotBroadCast />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <MobileTypecastLogoSection />
      <section className="md:flex-1">
        {isUserBroadcast ? (
          <UserBroadCastIntro
            startTime={data?.broadcasts[0].ivideo?.start_ts ?? 0}
          />
        ) : (
          <SampleBroadcastIntro />
        )}
        <AwsIvsPlayer />
      </section>
      <section className="md:flex-1 mt-12 md:mt-0">
        <ChatUsernameProvider>
          <AwsIvsChat />
        </ChatUsernameProvider>
      </section>
    </MainLayout>
  );
}

function MobileTypecastLogoSection() {
  return (
    <section className="bg-white px-4 py-3 md:hidden">
      <TypecastLogo />
    </section>
  );
}

function MainLayout({ children }: React.PropsWithChildren) {
  return (
    <main className="w-full h-full md:flex bg-black md:p-6 md:gap-6 p-3 overflow-y-scroll">
      {children}
    </main>
  );
}

const MAX_SECONDS = 60 * 5; //5min

function UserBroadCastIntro({ startTime }: { startTime: number }) {
  const { isMobile } = useScreenStore();
  const timer = useMemo(() => {
    const startTs = dayjs.unix(startTime);
    const now = dayjs();
    const diffSecond = now.diff(startTs, 'second');
    const leftSecond = MAX_SECONDS - diffSecond;
    return createTimeModel({
      initialTime: leftSecond * 1000,
      direction: 'backward',
      startImmediately: true,
    });
  }, [startTime]);
  const { value } = useTimeModel(timer);

  return (
    <div className="bg-grey-900 flex justify-between items-center p-4">
      <TDS.Typo
        className="mt-2 md:mt-0"
        color="white"
        size={isMobile ? 'sm' : 'lg'}
        weight="bold"
      >
        <span className="text-black bg-white">
          {value.m}: {value.s.toString().padStart(2, '0')}
        </span>
        <span className="ml-1.5">left in the live stream</span>
      </TDS.Typo>
      {GO_TO_CONSOLE && <StartStreamingButton />}
    </div>
  );
}

/**
 * @TODO 경로가 카밀라, 찬구, 하준인 경우만 보여주도록 수정
 */
function SampleBroadcastIntro() {
  const { isMobile } = useScreenStore();

  return (
    <>
      <div className="bg-grey-900 p-6 rounded-xl">
        <div className="md:text-center md:flex md:justify-between md:items-center">
          <TDS.Typo
            className="text-white"
            weight="bold"
            size={isMobile ? 'sm' : 'lg'}
          >
            {/* &apos; => ' */}
            Welcome to Typecast&apos;s sample live streaming broadcast!
          </TDS.Typo>
          {GO_TO_CONSOLE && <StartStreamingButton />}
        </div>
        {/* MEMO: 채널운영은 카밀라만 하기 때문에 캐릭터 선택란을 주석처리하였습니다. 추후 다른 캐릭터도 사용할 때 아래 주석을 풀어주세요. */}
        {/* <CharacterChip /> */}
      </div>
    </>
  );
}

function NotBroadCast(): React.ReactElement {
  const openConsole = () => {
    window.open(BUSINESS_CONTACT_URL);
    // if (isLocalOnlyDevMode) {
    //   window.open('http://localhost:3000', '_blank');
    //   return;
    // }
    // window.open(import.meta.env.VITE_LIVE_CONSOLE_URL, '_blank');
  };
  return (
    <div className="relative w-full h-full">
      <header className="flex flex-col items-center">
        <div className="flex flex-col items-end gap-0.5">
          <img src={TypecastLogoWhite} alt="Typecast Logo" />
          <div>
            <Text
              as="span"
              className="truncate"
              fontWeight="medium"
              color="white"
            >
              Live Streaming
            </Text>
            <Text
              as="span"
              className="border-2 ml-0.5 border-solid text-white p-0.5 rounded-lg"
            >
              Beta
            </Text>
          </div>
        </div>
      </header>
      <div className="w-80 p-8 bg-white rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img className="mx-auto" src={broadcast} alt="broadcast" />
        <Text className="text-center mt-2 text-5" fontWeight="medium">
          No active live stream
        </Text>
        {GO_TO_CONSOLE && (
          <div className="flex justify-center mt-6">
            <TDS.Button
              size="sm"
              variant="outline"
              colorScheme="grey"
              onClick={openConsole}
            >
              <Text>Go to console</Text>
            </TDS.Button>
          </div>
        )}
      </div>
    </div>
  );
}
