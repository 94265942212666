import {
  Box,
  Image,
  Text,
  Button,
  Tooltip,
  Spinner,
  Flex,
} from '@chakra-ui/react';

import { useEffect, useRef, useState, ReactElement } from 'react';

import { Ivideo } from '@/api/streaming-api';
import LeftIcon from '@/assets/icons/t-icon-left.svg';
import MuteIcon from '@/assets/icons/t-icon-volume-off.svg';
import MutedIcon from '@/assets/icons/t-icon-volume-on.svg';
import { useBroadcastsQueryWithParams } from '@/hooks/use-broadcasts-query-with-params';
import { gotoTypecast } from '@/utils';
import { gtag } from '@/utils/gtag';

export function AwsIvsPlayer(): ReactElement {
  const [isMuted, setIsMuted] = useState(true);
  const { data } = useBroadcastsQueryWithParams();

  const broadcasts = data?.broadcasts ?? [];
  const ivideo = broadcasts.length > 0 ? broadcasts[0].ivideo : null;
  const ouputUrl = ivideo?.output_url ?? '';

  const { videoRef, isPlaying, isLoading } = useIVSPlayer(ouputUrl, ivideo);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  return (
    <div className="my-4 md:my-0 relative">
      {isLoading && (
        <Spinner
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          thickness="4px"
          size="xl"
          color="white"
        />
      )}
      <video
        className="w-full"
        id="video-player"
        ref={videoRef}
        playsInline
        autoPlay
        muted
      />
      {isMuted && isPlaying && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="text-white bg-black/50"
        >
          <Box
            width="209px"
            height="48px"
            pl="24px"
            pr="24px"
            bg="rgba(255, 255, 255, 0.36)"
            borderRadius="10px"
            justifyContent="center"
            alignItems="center"
            gap="6px"
            display="inline-flex"
            cursor="pointer"
            onClick={() => {
              gtag('unmute_button_click');
              toggleMute();
            }}
          >
            <Image src={MuteIcon} alt="Mute Icon" />
            <Text fontSize="18px" fontWeight="600">
              Click to unmute
            </Text>
          </Box>
        </Box>
      )}
      {isPlaying && (
        <Flex
          position="absolute"
          left={0}
          right={0}
          bottom={-10}
          justifyContent="space-between"
        >
          <Button onClick={gotoTypecast} variant="unstyled">
            <Flex alignItems="center">
              <Image src={LeftIcon} alt="Goto Typecast" />
              <Text className="text-white">Go to Typecast</Text>
            </Flex>
          </Button>
          <Tooltip
            hasArrow
            placement="top"
            bg="grey.900"
            color="white"
            px="2"
            py="0.5"
            label={isMuted ? 'Unmute' : 'Mute'}
          >
            <Button
              onClick={() => {
                gtag('mute_button_click');
                toggleMute();
              }}
              variant="unstyled"
            >
              {isMuted ? (
                <Image src={MuteIcon} alt="Mute" />
              ) : (
                <Image src={MutedIcon} alt="Unmute" />
              )}
            </Button>
          </Tooltip>
        </Flex>
      )}
    </div>
  );
}

const useIVSPlayer = (playUrl: string, ivideo: Ivideo) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const MediaPlayerPackage = (window as any).IVSPlayer;

    if (!MediaPlayerPackage?.isPlayerSupported) {
      console.warn(
        'The current browser does not support the Amazon IVS player.',
      );
      return;
    }

    if (!ivideo) {
      return;
    }

    const player = MediaPlayerPackage.create();
    const videoElement = videoRef.current;

    if (!videoElement) {
      console.warn('Video element not found');
      return;
    }

    player.attachHTMLVideoElement(videoElement);

    const handlePlaying = () => {
      console.warn('Player State - PLAYING');
      setIsPlaying(true);
      setIsLoading(false);
    };

    const handleEnded = () => {
      console.warn('Player State - ENDED');
      setIsPlaying(false);
      setIsLoading(false);
    };

    const handleReady = () => {
      console.warn('Player State - READY');
      setIsPlaying(false);
    };

    const handleError = (err: unknown) => {
      if (!checkPlayerError(err)) {
        throw err;
      }

      /**
       * @object
       * code: -1;
       * message: 'Failed to fetch';
       * source: 'MasterPlaylist';
       * type: 'ErrorNetworkIO';
       */
      if (err.type === 'ErrorNetworkIO' && err.message === 'Failed to fetch') {
        setTimeout(() => {
          setIsLoading(true);
          player.load(playUrl);
        }, 3000);
        return;
      }

      /**
       * @object
       * code: 404;
       * message: 'Failed to load playlist';
       * source: 'MasterPlaylist';
       * type: 'ErrorNotAvailable';
       */
      if (
        err.type === 'ErrorNotAvailable' &&
        err.message === 'Failed to load playlist'
      ) {
        setTimeout(() => {
          setIsLoading(true);
          player.load(playUrl);
        }, 3000);
        return;
      }
    };

    player.addEventListener(
      MediaPlayerPackage.PlayerState.PLAYING,
      handlePlaying,
    );
    player.addEventListener(MediaPlayerPackage.PlayerState.ENDED, handleEnded);
    player.addEventListener(MediaPlayerPackage.PlayerState.READY, handleReady);
    /**
     * TODO: 에러 발생시 다시 복구할 수 있도록 기능 추가?
     */
    player.addEventListener(
      MediaPlayerPackage.PlayerEventType.ERROR,
      handleError,
    );

    // const isInitialPlay = window.sessionStorage.getItem(SESSION_KEY) === 'true';
    setIsLoading(true);
    player.setAutoplay(true);
    player.load(playUrl);

    return () => {
      player.removeEventListener(
        MediaPlayerPackage.PlayerState.PLAYING,
        handlePlaying,
      );
      player.removeEventListener(
        MediaPlayerPackage.PlayerState.ENDED,
        handleEnded,
      );
      player.removeEventListener(
        MediaPlayerPackage.PlayerState.READY,
        handleReady,
      );
      player.removeEventListener(
        MediaPlayerPackage.PlayerEventType.ERROR,
        handleError,
      );
      player.pause();
      player.delete();
    };
  }, [playUrl, ivideo]);

  return { videoRef, isPlaying, isLoading };
};

type PlayerError = {
  code: number;
  message: string;
  source: string;
  type: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkPlayerError = (err: any): err is PlayerError => {
  return 'code' in err && 'message' in err && 'source' in err && 'type' in err;
};
